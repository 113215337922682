<template>
  <div id="payCheck">
    <div class="checktips" v-if="!!payType">
      <img src="@/assets/icons/wx.png" alt class="payImg" v-if="payType == 5 ||payType== 7" />
      <img
        src="@/assets/icons/zfb.png"
        alt
        class="payImg"
        v-if="payType == 12"
      />
      <img
        src="@/assets/icons/yidong.png"
        alt
        class="payImg"
        v-if="payType == 19"
      />
      <img
        src="@/assets/icons/yidong.png"
        alt
        class="payImg"
        v-if="payType == 22"
      />
      请确认{{ payMethod(payType) }}支付是否完成
    </div>
    <div class="checkBtns">
      <button
        class="checkBtn main"
        type="button"
        :disabled="loading"
        @click="checkOrder"
      >
        已完成支付
      </button>
      <button
        class="checkBtn"
        type="button"
        :disabled="loading"
        @click="goHome"
      >
        返回商户
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "payCheck",
  data() {
    return {
      bizId: this.$route.query.bizId,
      skuId: this.$route.query.skuId,
      sceneNo:this.$route.query.sceneNo,
      payType: this.$route.query.payType, //  支付类型
      loading: false,
      productId: this.$route.query.productId
    };
  },
  components: {},
  created() {},
  methods: {
    //  付款方式
    payMethod(payType) {
      let txt;
      switch (payType) {
        case 5:
          txt = "微信";
          break;
        case 7:
          txt = "微信";
          break;
        case 12:
          txt = "支付宝";
          break;
        case 19:
          txt = "移动话费";
          break;
        case 22:
          txt = "钱包支付";
          break;
      }
      return txt;
    },
    //  检查订单
    checkOrder() {
      this.loading = true;
       if(this.$route.query.virtual){
         console.log('虚拟')
           this.$router.replace({
             path: "/h/scm/order/orderList",
             query: {
              from: "hotSale",
               sceneNo:this.sceneNo
              }
          });
          return;
       }
      this.request
        .get("/api/scm/pay/query", {
          bizId: this.bizId
        })
        .then((res) => {
          let type = res && res.status,
            tips = !type
              ? "订单处理中，若您确认已经支付，请稍候刷新订单详情页面同步订单状态"
              : type == 2
              ? "该订单已被取消"
              : "交易成功";
          this.$toast({
            message: tips,
            position: "bottom",
            duration: !type ? 5000 : 3000
          });
          this.$router.replace({
            path: "/h/scm/order/orderDetail",
            query: {
              skuId: this.skuId,
              bizId: this.bizId,
              sceneNo:this.sceneNo
            }
          });
        
        });
    },
    //  返回订单列表
    goHome() {
      this.$router.replace({
       name: 'hotSale',query:{scence:this.$route.query.sceneNo}
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.checktips {
  text-align: center;
  font-size: 34px;
  font-weight: bold;
  .payImg {
    display: block;
    height: 65px;
    margin: 130px auto 45px;
  }
}
.checkBtns {
  text-align: center;
  margin: 120px 50px 20px;
  .checkBtn {
    font-weight: bold;
    display: block;
    height: 96px;
    width: 100%;
    text-align: center;
    background: transparent;
    margin: 0 auto 50px;
    font-size: 36px;
    /* prettier-ignore */
    border: 1PX solid #999;
    color: #999;
    border-radius: 96px;
    &.main {
      border-color: transparent;
      color: #fff;
      background: linear-gradient(to right, #f62300, #fa5421);
    }
  }
}
</style>